$(function () {
    $('[data-event]').click(function(e) {
        var key = $(this).attr('data-event');
        var events = {
            'main-page_click': {
                event: 'userAction',
                category: 'menu',
                action: 'main-page_click',
                label: 'kinderdziendziecka_2024'
            },
            'prizes_click': {
                event: 'userAction',
                category: 'menu',
                action: 'prizes_click',
                label: 'kinderdziendziecka_2024'
            },
            'rules_click': {
                event: 'userAction',
                category: 'menu',
                action: 'rules_click',
                label: 'kinderdziendziecka_2024'
            },
            'join-in_click': {
                event: 'userAction',
                category: 'menu',
                action: 'entry_click',
                label: 'kinderdziendziecka_2024'
            },
            'products_click': {
                event: 'userAction',
                category: 'menu',
                action: 'products_click',
                label: 'kinderdziendziecka_2024'
            },
            'joy-being-together_click': {
                event: 'userAction',
                category: 'menu',
                action: 'joy-being-together_click',
                label: 'kinderdziendziecka_2024'
            },
            'contact_click': {
                event: 'userAction',
                category: 'menu',
                action: 'contact_click',
                label: 'kinderdziendziecka_2024'
            },
            'lottery-rules_click': {
                event: 'userAction',
                category: 'rules',
                action: 'lottery-rules_click',
                label: 'kinderdziendziecka_2024'
            },
            'voucher-decathlon_click': {
                event: 'userAction',
                category: 'rules',
                action: 'voucher-decathlon_click',
                label: 'kinderdziendziecka_2024'
            },
            'voucher-wakacjepl_click': {
                event: 'userAction',
                category: 'rules',
                action: 'voucher-wakacjepl_click',
                label: 'kinderdziendziecka_2024'
            },
            'contact-us_click': {
                event: 'userAction',
                category: 'contact',
                action: 'contact-us_click',
                label: 'kinderdziendziecka_2024'
            },
            'join-in-click-btn': {
                event: 'userAction',
                category: 'WebButton',
                action: 'join-in-click',
                label: 'kinderdziendziecka_2024'
            },
            'button-join-in-click' : {
                event: 'userAction',
                category: 'main-page',
                action: 'join-in_click',
                label: 'kinderdziendziecka_2024'
            },
            'join-in_contest': {
                event: 'userAction',
                category: 'join-in_contest',
                action: 'contest_join_now_click',
                label: 'kinderdziendziecka_2024'
            },
            'join-in_contest_later': {
                event: 'userAction',
                category: 'join-in_contest',
                action: 'contest_join_later_click',
                label: 'kinderdziendziecka_2024'
            },
            'email_click': {
                event: 'userAction',
                category: 'contact',
                action: 'email_click',
                label: 'kinderdziendziecka_2024'
            },
            'floating_button_click': {
                event: 'userAction',
                category: 'WebButton',
                action: 'join-in_click',
                label: 'kinderdziendziecka_2024'
            },
            'rule3_more_click': {
                event: 'userAction',
                category: 'form',
                action: 'read-more-information-clause_click',
                label: 'kinderdziendziecka_2024'
            },
            'rule4_more_click': {
                event: 'userAction',
                category: 'form',
                action: 'read-more-personal-data_click',
                label: 'kinderdziendziecka_2024'
            },
            'check_click': {
                event: 'userAction',
                category: 'joy-being-together',
                action: 'check_click',
                label: 'kinderdziendziecka_2024'
            },
            'contact-us_click': {
                event: 'userAction',
                category: 'contact',
                action: 'contact-us_click',
                label: 'kinderdziendziecka_2024'
            }
        }

        if (typeof dataLayer !== 'undefined') {
            if (typeof events[key] !== 'undefined') {
                dataLayer.push(events[key]);
                // dataLayer.push({
                //     'event': 'gate_pass'
                // });
            }
        }
    });

    $('input[name="personal_data[files][paragon]"]').on('change', function () {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                event: 'userAction',
                category: 'form',
                action: 'recipe_sent',
                label: 'kinderdziendziecka_2024'
            });
        }
    });

    $('[data-event-fill]').focusout(function(e) {
        var key = $(this).data('event-fill');
        var events = {
            'name_fill': {
                event: 'userAction',
                category: 'form',
                action: 'name_fill',
                label: 'kinderdziendziecka_2024'
            },
            'surname_fill': {
                event: 'userAction',
                category: 'form',
                action: 'surname_fill',
                label: 'kinderdziendziecka_2024'
            },
            'email_fill': {
                event: 'userAction',
                category: 'form',
                action: 'email_fill',
                label: 'kinderdziendziecka_2024'
            },
            'receipt_no_fill': {
                event: 'userAction',
                category: 'form',
                action: 'recipe-number_fill',
                label: 'kinderdziendziecka_2024'
            },
            'shop-nip_fill': {
                event: 'userAction',
                category: 'form',
                action: 'shop-nip_fill',
                label: 'kinderdziendziecka_2024'
            },
            'recipe-number_sent': {
                event: 'userAction',
                category: 'form',
                action: 'recipe-number_sent',
                label: 'kinderdziendziecka_2024'
            },
            'recipe-nip_sent': {
                event: 'userAction',
                category: 'form',
                action: 'shop-nip_fill',
                label: 'kinderdziendziecka_2024'
            },
            'message_fill': {
                event: 'userAction',
                category: 'join-in_contest',
                action: 'childs_talent_descr_fill',
                label: 'kinderdziendziecka_2024'
            },
            'contact_name_fill': {
                event: 'userAction',
                category: 'contact',
                action: 'name_fill',
                label: 'kinderdziendziecka_2024'
            },
            'message-subject_fill' : {
                event: 'userAction',
                category: 'contact',
                action: 'message-subject_fill',
                label: 'kinderdziendziecka_2024'
            },
            'contact_email_fill': {
                event: 'userAction',
                category: 'contact',
                action: 'email_fill',
                label: 'kinderdziendziecka_2024'
            },
            'contact_massage_fill': {
                event: 'userAction',
                category: 'contact',
                action: 'massage_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_name_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'name_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_surname_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'surname_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_phone_nr_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'phone_nr_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_email_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'email_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_street_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'street_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_building_nr_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'building_nr_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_apartm_nr_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'apartm_nr_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_zip_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'zip_fill',
                label: 'kinderdziendziecka_2024'
            },
            'winner_city_fill': {
                event: 'userAction',
                category: 'join-in_delivery',
                action: 'city_fill',
                label: 'kinderdziendziecka_2024'
            },
        }

        if (typeof dataLayer !== 'undefined') {
            if (typeof events[key] !== 'undefined') {
                if ($(this).val()) {
                    dataLayer.push(events[key]);
                }
            }
        }
    });

    $('#form-agree-all').on('change', function () {
        if ($('.application-rule:checked').length === $('.application-rule').length) {
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    event: 'userAction',
                    category: 'form',
                    action: 'consent_click',
                    label: 'kinderdziendziecka_2024'
                });
            }
        }
    });

    $('.application-rule').on('change', function () {
        if ($('.application-rule:checked').length === $('.application-rule').length) {
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    event: 'userAction',
                    category: 'form',
                    action: 'consent_click',
                    label: 'kinderdziendziecka_2024'
                });
            }
        }
    });

    $('#form-agree-5').on('change', function () {
        if($(this).is(':checked')) {
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    event: 'userAction',
                    category: 'contact',
                    action: 'consent_click',
                    label: 'kinderdziendziecka_2024'
                });
            }
        }
    });
    //
    // $('.optional-application-rule').on('change', function () {
    //     if ($('.optional-application-rule:checked').length === $('.optional-application-rule').length) {
    //         if (typeof dataLayer !== 'undefined') {
    //             dataLayer.push({
    //                 event: 'userAction',
    //                 category: 'form',
    //                 action: 'consent_click',
    //                 label: 'kinderdziendziecka_2024'
    //             });
    //         }
    //     }
    // });

    $('.winner_rule').on('change', function () {
        if ($('.winner_rule:checked').length === $('.winner_rule').length) {
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    event: 'userAction',
                    category: 'join-in_delivery',
                    action: 'consent_terms_conditions_click',
                    label: 'kinderdziendziecka_2024'
                });
            }
        }
    });

    $('.competition-rule').on('change', function () {
        if ($('.competition-rule:checked').length === $('.competition-rule').length) {
            if (typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    event: 'userAction',
                    category: 'join-in_contest',
                    action: 'consent_terms_conditions_click',
                    label: 'kinderdziendziecka_2024'
                });
            }
        }
    });

    var landingSection = $('#landing').offset().top;
    var applicationSection = $('#application').offset().top;
    var productsSection = $('#products').offset().top;
    var prizesSection = $('#prizes').offset().top;
    var rulesSection = $('#rules').offset().top;
    var joySection = $('#joy').offset().top;
    var contactSection = $('#contact-section').offset().top;

    var section = '';
    $(window).on('scroll', function() {
        var y_scroll_pos = window.pageYOffset;

        if(y_scroll_pos > landingSection - 120 && y_scroll_pos < landingSection + $('#landing').height()) {
            if (section == '' || section != 'landing') {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'section_view',
                        action: 'main-page_view',
                        label: 'kinderdziendziecka_2024'
                    });
                }
            }
            section = 'landing';
        }
        if(y_scroll_pos > applicationSection - 120 && y_scroll_pos < applicationSection + $('#application').height()) {
            if (section == '' || section != 'application') {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'section_view',
                        action: 'join-in_view',
                        label: 'kinderdziendziecka_2024'
                    });
                }
            }
            section = 'application';
        }
        if(y_scroll_pos > prizesSection - 120 && y_scroll_pos < prizesSection + $('#prizes').height()) {
            if (section == '' || section != 'prizes') {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'section_view',
                        action: 'prizes_view',
                        label: 'kinderdziendziecka_2024'
                    });
                }
            }
            section = 'prizes';
        }
        if(y_scroll_pos > rulesSection - 120 && y_scroll_pos < rulesSection + $('#rules').height()) {
            if (section == '' || section != 'rules') {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'section_view',
                        action: 'rules_view',
                        label: 'kinderdziendziecka_2024'
                    });
                }
            }
            section = 'rules';
        }
        if(y_scroll_pos > joySection - 120 && y_scroll_pos < joySection + $('#joy').height()) {
            if (section == '' || section != 'joy') {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'section_view',
                        action: 'joy-being-together_view',
                        label: 'kinderdziendziecka_2024'
                    });
                }
            }
            section = 'joy';
        }
        if(y_scroll_pos > productsSection - 120 && y_scroll_pos < productsSection + $('#products').height()) {
            if (section == '' || section != 'products') {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'section_view',
                        action: 'products_view',
                        label: 'kinderdziendziecka_2024'
                    });
                }
            }
            section = 'products';
        }

        if(y_scroll_pos > contactSection - 120 - $('#contact-section').height() && y_scroll_pos < contactSection + $('#contact-section').height()) {
            if (section == '' || section != 'contact') {
                if (typeof dataLayer !== 'undefined') {
                    dataLayer.push({
                        event: 'userAction',
                        category: 'section_view',
                        action: 'contact_view',
                        label: 'kinderdziendziecka_2024'
                    });
                }
            }
            section = 'contact';
        }
    });
});
