import * as bootstrap from "bootstrap5";

/*$("#personal_data_billTypeCheckbox").on("change", function () {
	let checked = $(this).prop("checked");
	if (checked) {
		$("#personal_data_billType").attr("value", "Faktura");
	} else {
		$("#personal_data_billType").attr("value", "Paragon");
	}
	$("#personal_data_billPos").prop("disabled", checked);
	$("#personal_data_billPos").prop("required", !checked);
	$("#personal_data_billPos").val("");
});*/

$("#personal_data_pesel_checkbox").on("change", function () {
	let checked = $(this).prop("checked");
	$("#personal_data_pesel").prop("disabled", checked);
	$("#personal_data_pesel").prop("required", !checked);
	$("#personal_data_pesel").val("");
	$(".fieldset-pesel").toggleClass("d-none").prop("disabled", !checked);
});

/*$("input.iban-check").each(function () {
	$(this).on("change", function () {
		let checked = $(this).val();
		$("input.iban-check").prop("checked", false);
		$(this).prop("checked", true);
		$("fieldset.fieldset-iban-record-yes")
			.toggleClass("d-none")
			.prop("disabled", (i, v) => !v);
		$("fieldset.fieldset-iban-record-no")
			.toggleClass("d-none")
			.prop("disabled", (i, v) => !v);
	});
});

// Only accept numbers for input bill NIP
$("#personal_data_billNip").on("input", function () {
	$(this).val($(this).val().replace(/[^0-9]/g, ""));
});

$('.form-group__modal').on('click', function () {
    const target = $(this).data('target');
    new bootstrap.Modal(`${target}`, {}).show();
});*/

$("form#form-application").on("submit-success", function (event, data) {
    new bootstrap.Modal(`#modal-forms-application-submit`, {}).show();
    //$('body')[0].scrollIntoView({ /*behavior: "smooth",*/ block: "start" });

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: 'userAction',
            category: 'form',
            action: 'form_sent',
            label: 'kinderdziendziecka_2024'
        });
    }

});
$('#modal-forms-application-submit').on('hide.bs.modal', function() {
	$('body')[0].scrollIntoView({ behavior: "smooth", block: "start" });
});

$("form#form-application").on("submit-invalid", function (event, data) {
	$('.s-form')[0].scrollIntoView({ behavior: "smooth", block: "start" });

	let firstError = $(this).find('.invalid-feedback:visible:first').text();

    let eventName = 'form_fail_sent';

	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			event: 'userAction',
			category: 'form',
			action: '' + eventName + '',
			label: 'kinderdziendziecka_2024',
			fail: firstError
		});
	}
});

$("form#form-application").on("submit-error", function (event, data) {
	let error = '';
	$('.s-form')[0].scrollIntoView({ behavior: "smooth", block: "start" });
	if (data.responseJSON && data.responseJSON.errors) {
		Object.values(data.responseJSON.errors).forEach(function (errorItem) {
			if ($.isArray(errorItem)) {
				errorItem.forEach(function (message) {
					error += message + '; ';
				});
			} else {
				error += errorItem + '; ';
			}
		});
	} else {
		error = 'Wystąpił nieznany błąd, spróbuj ponownie później.';
	}

	let eventName = '';

    eventName = 'lottery_form_fail_sent'

	if (typeof dataLayer !== 'undefined') {
		const errors = error.split(';');

		dataLayer.push({
			event: 'userAction',
			category: 'join-in_lottery',
			action: '' + eventName + '',
			label: 'kinderdziendziecka_2024',
			fail: '' + errors[0] + ''
		});
	}
});

$("form#form-contact").on("submit-success", function (event, data) {
    $('#m-contact').modal('hide');
	$('#modal-forms-contact-submit').modal('show');

	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			event: 'userAction',
			category: 'contact',
			action: 'send_click',
            label: 'kinderdziendziecka_2024',
		});
	}
});

$("form#form-contact").on("submit-invalid", function (event, data) {
	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			event: 'userAction',
			category: 'contact',
			action: 'send-fail_click',
            label: 'kinderdziendziecka_2024',
		});
	}
});

$("form#form-contact").on("submit-error", function (event, data) {
	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			event: 'userAction',
			category: 'contact',
			action: 'send-fail_click',
			label: 'kinder-dzien-dziecka_2024'
		});
	}
});

$("form#form-winner").on("submit-success", function (event, data) {
	new bootstrap.Modal(`#modal-forms-winner-submit`, {}).show();
	$('.form-winner-container-filled').removeClass('d-none');
	$('.form-winner-container').remove();

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: 'userAction',
            category: 'join-in_delivery',
            action: 'delivery_form_sent',
            label: 'kinderdziendziecka_2024',
        });
    }
});

$("form#form-winner").on("submit-invalid", function (event, data) {
    $('#winner')[0].scrollIntoView({ behavior: "smooth", block: "start" });
    let error = data;
    const errors = error.split(';');

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: 'userAction',
            category: 'join-in_delivery',
            action: 'delivery_form_sent-fail',
            label: 'kinderdziendziecka_2024',
            fail: '' + errors[0] + ''
        });
    }
});

$("form#form-winner").on("submit-error", function (event, data) {
    let error = '';
    $('#winner')[0].scrollIntoView({ behavior: "smooth", block: "start" });

    if (data.responseJSON && data.responseJSON.errors) {
        Object.values(data.responseJSON.errors).forEach(function (errorItem) {
            if ($.isArray(errorItem)) {
                errorItem.forEach(function (message) {
                    error += message + '; ';
                });
            } else {
                error += errorItem + '; ';
            }
        });
    } else {
        error = 'Wystąpił nieznany błąd, spróbuj ponownie później.';
    }

    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            event: 'userAction',
            category: 'join-in_delivery',
            action: 'delivery_form_sent-fail',
            label: 'kinderdziendziecka_2024',
            fail: '' + error + ''
        });
    }
});

$("#button-contact").on("click", function () {
	$(this).addClass("d-none");
	$('.contact-text-container').addClass('d-none');
	$('.contact-us-button-container').addClass('d-none');
	$("form#form-contact").removeClass("d-none");
});

$("#close-contact-form").on("click", function () {
	$('#button-contact').removeClass('d-none');
	$('.contact-text-container').removeClass('d-none');
	$("form#form-contact").addClass("d-none");
});

$("#contact-thanks-container").on("click", function () {
	$('#button-contact').removeClass('d-none');
	$('.contact-text-container').removeClass('d-none');
	$("#contact-thanks-container").addClass("d-none");
});

// $('#close-link-thanks').on('click', function () {
// 	$('#link-thanks-container').addClass('d-none');
// });

$("form#form-code").on("submit-success", function (event, data) {
	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			event: 'userAction',
			category: 'join-in_lottery',
			action: 'continue_click',
			label: 'kinder-dzien-dziecka_2024'
		});
	}

	const code = $('#personal_data_code').val();
	const type = code.charAt(2);

	$('input[name="code"]').val(code);

	if (type === '2') {
		const code2 = $('#personal_data_code2').val();

		$('input[name="code2"]').val(code2);
	}

	$("form#form-application").removeClass('d-none');
	$('.for-code-form').addClass('d-none');
	$("form#form-code").addClass('d-none');

	$("#application .bicycle").removeClass('d-xl-block');
	$("#application .ball").removeClass('d-xl-block');

	$('#step-text').text('Krok 2');
});

$("form#form-code").on("submit-invalid", function (event, data) {
	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			event: 'userAction',
			category: 'join-in_lottery',
			action: 'continue_failed_click',
			label: 'kinderdziendziecka_2024',
			fail: 'Ten kod już został wykorzystany lub nie jest poprawny.'
		});
	}
});

$("form#form-code").on("submit-error", function (event, data) {
	if (typeof dataLayer !== 'undefined') {
		dataLayer.push({
			event: 'userAction',
			category: 'join-in_lottery',
			action: 'continue_failed_click',
			label: 'kinderdziendziecka_2024',
			fail: 'Ten kod już został wykorzystany lub nie jest poprawny.'
		});
	}
});
